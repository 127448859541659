<template>
	<div>
		<a-modal v-drag-modal v-model="repayModalShow" :title="$t('table.finance.customerFee.uploadRepayments')" :destroyOnClose="true" width="80%">
			<div v-if="parentSource != 'repaymentEntryEarly2'">
				<a-card :title="$t('table.finance.repayment.tabs.loanInfo')" size="small">
					<a-form-model layout="inline">
						<a-form-model-item>
							<a-input v-model="searchKeyword" placeholder="REG/Mobile/Name" :disabled="needAutoSearch" />
						</a-form-model-item>
						<a-form-model-item>
							<a-button type="primary" @click="searchLoan" v-t.preserve="'operate.search2'" :disabled="needAutoSearch"></a-button>
						</a-form-model-item>
						<a-table
							style="font-size: 12px"
							:columns="loanColumns"
							size="small"
							:rowKey="(record, index) => index"
							:dataSource="loanDataSource"
							:pagination="false"
							:row-selection="{
								fixed: true,
								type: 'radio',
								columnWidth: 100,
								columnTitle: this.$t('table.finance.cloums.choose'),
								onChange: changeRow,
								selectedRowKeys: selectedRowKeys
							}"
							:scroll="{ x: '70%' }"
							bordered
						>
							<a-statistic slot="money_slot" slot-scope="text" :precision="2" :value="text === null ? ' - ' : text" />
							<span slot-scope="obj" slot="action">
								<a-button type="primary" @click="Detail(obj)">{{ $t('page.checkDetail') }}</a-button>
							</span>
						</a-table>
					</a-form-model>
				</a-card>
				<a-radio-group v-model="repayRadio" button-style="solid" @change="ChangeRepayRadio" style="margin: 10px 0;" v-if="parentSource === 'repaymentEntry'">
					<a-radio-button :value="0" class="btn_radio">
						Manually Input
					</a-radio-button>
					<a-radio-button :value="1" class="btn_radio" v-if="preRepays.length > 0">
						From Pre-Payments
					</a-radio-button>

				</a-radio-group>
				<a-table v-if="parentSource === 'repaymentEntryEarly'" :columns="prePaymentColumns" size="small" :rowKey="(record, index) => index" :dataSource="preRepays" :pagination="false" bordered class="border-right" style="margin: 10px 0;">
					<b slot="title">Pre-Payment Details</b>
					<span slot="action" slot-scope="obj">
						<a-checkbox v-model="obj.checked" @change="ChangeCheck">Tick to use it for Early Settlement</a-checkbox>
					</span>
				</a-table>

				<a-table v-if="repayRadio == 1" :columns="prePaymentColumns" size="small" :rowKey="(record, index) => index" :dataSource="preRepays" :pagination="false" bordered class="border-right" style="margin: 10px 0;">
					<b slot="title">Pre-Payment Details</b>
					<span slot="action" slot-scope="obj, text, index">
						<a-radio :checked="index == 0" :disabled="index>0">Select it to use for repayment</a-radio>
					</span>
				</a-table>

				<a-card :title="parentSource === 'repaymentEntryEarly'?'Early Settlement - Repayment Records':'Auction Settlement - Repayment Records'" size="small" style="margin: 10px 0;" v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryAuction'">
					<a-table
						style="font-size: 12px; margin-top: 10px;"
						:columns="earlyRecordColunms"
						size="small"
						:rowKey="(record) => record.id"
						:dataSource="parentSource === 'repaymentEntryEarly'?repays2: repays"
						:pagination="false"
						bordered
					>
						<span slot="receipt" slot-scope="obj">
							<template v-if="obj.transactionFiles">
								<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
									<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
								</viewer>
								<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
									obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
								}}</a>
							</template>
						</span>
						<span slot="action" slot-scope="obj">
							<a-button type="danger" :disabled="obj.checked" @click="DeleteRepays(obj.id)">Delete</a-button>
						</span>
					</a-table>
				</a-card>
			</div>
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<div v-if="parentSource != 'repaymentEntryEarly2'">
					<a-form-model-item :label="$t('table.finance.cloum.payType')" v-if="repayRadio == 0">
						<a-radio-group v-model="formModelData.transactionTypeEnum" v-if="parentSource != 'repaymentEntryEarly' && parentSource != 'repaymentEntryAuction'">
							<a-radio :value="item" v-for="item in [1, 0, 4, 5, 3]" :key="item" :disabled="(needAutoFilling && item !== formModelData.transactionTypeEnum)">
								{{ $t(`table.finance.repayment.payTypeMap.${item}`) }}
							</a-radio>
						</a-radio-group>
						<a-radio-group v-model="formModelData.transactionTypeEnum" v-if="parentSource === 'repaymentEntryEarly'">
							<a-radio :value="item" v-for="item in [1, 0, 4, 5, 3]" :key="item" :disabled="(needAutoFilling && item !== formModelData.transactionTypeEnum)">
								{{ $t(`table.finance.repayment.payTypeMap.${item}`) }}
							</a-radio>
						</a-radio-group>
						<a-radio-group v-model="formModelData.transactionTypeEnum" v-if="parentSource === 'repaymentEntryAuction'">
							<a-radio :value="item" v-for="item in [2]" :key="item" :disabled="(needAutoFilling && item !== formModelData.transactionTypeEnum)">
								{{ $t(`table.finance.repayment.payTypeMap.${item}`) }}
							</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.cloum.referenceNo')"  v-if="repayRadio == 0">
						<a-input v-model="formModelData.paymentId" :disabled="needAutoFilling" />
					</a-form-model-item>
					<a-form-model-item
						v-if="formModelData.transactionTypeEnum == 2 && loanColumnData.carInfos.length > 1 && parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryAuction' && repayRadio == 0"
						:label="$t('table.CustomerManagement.fromdata.licensePlateNumber')"
					>
						<a-select v-model="formModelData.carPlateNo">
							<a-select-option :value="item.id" v-for="item in loanColumnData.carInfos" :key="item.id">
								{{ item.registrationNumber }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.cloum.actual')" v-if="repayRadio == 0">
						<a-input-number v-model="formModelData.amount" :min="0" :precision="0" :disabled="needAutoFilling" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.cloum.excutionTime')" v-if="repayRadio == 0">
						<a-date-picker v-model="formModelData.transactionDate" :format="dateFormat" :disabled-date="disabledDate" showTime type="date" :disabled="needAutoFilling" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.cloum.exactTime')" v-if="parentSource === 'bankAccountChecking'">
						<a-time-picker v-model="formModelData.transactionTime" format="HH:mm:ss" type="date" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.cloum.payCode')" v-if="!needAutoFilling && repayRadio == 0">
						<UploadPreviewFile :uploadFilePath.sync="formModelData.transactionFiles" :folderPath="loanColumnData.loanId"></UploadPreviewFile>
					</a-form-model-item>
					<a-form-model-item label="Document Password" v-if="!needAutoFilling && repayRadio == 0">
						<a-input v-model="formModelData.password" :disabled="needAutoFilling" />
					</a-form-model-item>
					<a-form-model-item v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryAuction'">
						<a-button type="primary" @click="AddEarlyRepay" style="margin: 0 200px">Save</a-button>
					</a-form-model-item>
				</div>
				<a-form-model-item label="Discount" v-if="parentSource !== 'repaymentEntryAuction'">
					<a-radio-group v-model="formModelData.isDiscount" :disabled="whetherToOverpay" @change="getEstimateAmount">
						<a-radio :value="0"> No </a-radio>
						<a-radio :value="1"> Yes </a-radio>
					</a-radio-group>
					<div v-if="formModelData.isDiscount === 1">
						<a-form-item label="Discount on Tracking Fee" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountTrackingAmount"
								v-model="formModelData.discountTrackingAmount"
								:precision="0"
								@change="getEstimateAmount"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountTrackingAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Loan Maintenance Fee" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountMaintenanceAmount"
								v-model="formModelData.discountMaintenanceAmount"
								:precision="0"
								style="margin-right: 10px"
								@change="getEstimateAmount"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountMaintenanceAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Interest" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountInterestAmount"
								v-model="formModelData.discountInterestAmount"
								:precision="0"
								style="margin-right: 10px"
								@change="getEstimateAmount"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountInterestAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Penalty" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountPenaltyAmount"
								v-model="formModelData.discountPenaltyAmount"
								:precision="0"
								style="margin-right: 10px"
								@change="getEstimateAmount"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountPenaltyAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Other Fees" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountOtherAmount"
								v-model="formModelData.discountOtherAmount"
								:precision="0"
								style="margin-right: 10px"
								@change="getEstimateAmount"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountOtherAmount}` }}
						</a-form-item>
					</div>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.reson')" v-if="formModelData.isDiscount === 1">
					<a-textarea v-model="formModelData.extraReason" :maxLength="300" style="width: 400px" :rows="4" />
				</a-form-model-item>
				<a-form-model-item label="NTSA Transfer Fee" v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryEarly2' || parentSource === 'repaymentEntryAuction'">
					<a-radio-group v-model="formModelData.inputNTSATransferFee"  @change="getEstimateAmount">
						<a-radio :value="1"> Input automatically </a-radio>
						<a-radio :value="0"> Do not input </a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="Choose the loan to repay" v-if="loanTypeRepayList.length > 0 && parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryEarly2' && parentSource !== 'repaymentEntryAuction'">
					<a-checkbox-group v-model="formModelData.repayLoan" @change="ChangeRepayLoan">
						<a-checkbox :value="settle.value" v-for="settle in loanTypeRepayList" :key="settle.value">
							{{ $t(`table.applyInfo.financialLabel.loanMap.${settle.loanType}`) }}
						</a-checkbox>
					</a-checkbox-group>
				</a-form-model-item>
				<div v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryEarly2' || parentSource === 'repaymentEntryAuction'">
					<a-form-model-item :label="parentSource === 'repaymentEntryAuction'?'Auction Settlement': 'Early Settlement'" v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryEarly2'">
						<a-checkbox-group @change="ChangeEarlyRepayLoan" v-model="earlyLoanType">
							<span v-for="settle in settlApiList" :key="settle.id">
								<a-checkbox :disabled="settle.disabled" :value="settle.loanType" style="margin-right: 40px">
									{{ $t(`table.applyInfo.financialLabel.loanMap.${settle.loanType}`) }}
								</a-checkbox>
								<div v-if="settle.checked === true">
									Please select the period that early settled:
									<a-select v-model="settle.installment" style="width: 200px" @change="getEstimateAmount">
										<a-select-option :value="instal" v-for="instal in settle.unsettledPeriods" :key="instal">
											{{ instal }}
										</a-select-option>
									</a-select>
								</div>
							</span>
						</a-checkbox-group>
					</a-form-model-item>
					<a-form-model-item>Actual Repayment：{{ estimateSettlementData.hadPaidAmount }}</a-form-model-item>
					<a-form-model-item>Remaining Outstanding：{{ estimateSettlementData.outstandingAmount }}</a-form-model-item>
				</div>
				<a-form-model-item :label="$t('table.finance.cloum.check')" v-if="parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryEarly2' && parentSource !== 'repaymentEntryAuction'">
					<a-select v-model="formModelData.needClosedLoan" :disabled="whetherToOverpay || needEarlySettlement === 1">
						<a-select-option :value="1">{{ $t('page.yes') }}</a-select-option>
						<a-select-option :value="0">{{ $t('page.no') }}</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
			<template slot="footer">
				<a-button key="back" @click="repayModalShow = false"> Cancel </a-button>
				<a-button @click="previewRepay" v-if="parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryEarly2' && parentSource !== 'repaymentEntryAuction'"> Preview </a-button>
				<a-button @click="previewEarlyRepay" v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryEarly2' || parentSource === 'repaymentEntryAuction'"> Preview </a-button>
				<a-button key="submit" type="primary" @click="handModalOk" v-if="parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryEarly2' && parentSource !== 'repaymentEntryAuction'"> Save </a-button>
				<a-button key="submit" type="primary" @click="SubmitEarlyRepament" v-if="parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryAuction'"> Submit </a-button>
			</template>
		</a-modal>
		<a-modal v-drag-modal v-model="previewRepayTable" title="Repayments Preview" width="85%" :footer="false" :destroyOnClose="true">
			<repayments-plan-list
				style="margin-bottom: 10px"
				:repayPlan.sync="previewRepayParams.repayPlan"
				:insurancePlans.sync="previewRepayParams.insurancePlans"
				:emergencyPlans.sync="previewRepayParams.emergencyPlans"
				:overpaidDetail.sync="previewRepayParams.overpaidDetail"
				:loanType="previewRepayParams.columnLoanType"
				:type="previewRepayParams.type"
			></repayments-plan-list>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import RepaymentsPlanList from '@/views/businessComponents/RepaymentsPlanList'
import {
	apiEarlyDetail,
	apiPreview,
	apiDiscountAmount,
	apiEstimateSettlement,
	apiLoanTransaction,
	apiFindLoanByName,
	apiUploadLoanTransaction,
	apiCheckLoanTransaction,
	apiOverpaidRepay,
	apiOverpaidUpload,
	apiDisburseEarlyInfo,
	apiDisburseRepayInfo,

	apiSaveEarly,
	apiEarlyPreview,
	apiEarlyEstimate,
	apiEarlyDelete,
	apiEarlySubmit
} from '@/api/loan'
import { apiMpesaUploadRepay, apiMpesaUploadOverpaid, apiDtbUploadRepay, apiDtbUploadOverpaid } from '@/api/accountChecking'
export default {
	components: { RepaymentsPlanList },
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			repayModalShow: false,
			loanColumnData: {},
			parentSource: '',
			loanDataSource: [],
			selectedRows: [],
			selectedRowKeys: [],
			needAutoSearch: false,
			searchKeyword: '',
			whetherToOverpay: false,
			needAutoFilling: false,
			formModelData: {
				loanId: '',
				paymentId: '',
				transactionTypeEnum: 0,
				carPlateNo: '',
				transactionDate: '',
				transactionTime: null,
				transactionFiles: '',
				amount: '',
				isDiscount: 0,
				discountInterestAmount: 0,
				discountPenaltyAmount: 0,
				discountTrackingAmount: 0,
				discountMaintenanceAmount: 0,
				discountOtherAmount: 0,
				extraReason: '',
				needClosedLoan: 0,
				settlementParams: [],
				repayLoan: [0],
				password: '',
				inputNTSATransferFee: 0,
			},
			settlApiList: [],
			needEarlySettlement: 0,
			previewRepayTable: false,
			previewRepayParams: {
				type: 0,
				repayPlan: [],
				insurancePlans: [],
				emergencyPlans: [],
				overpaidDetail: [],
				columnLoanType: ''
			},
			apiDiscountData: {
				discountInterestAmount: 0, //可平账的利息
				discountPenaltyAmount: 0, //可平账的罚息
				discountTrackingAmount: 0, //可平账的gps费用
				discountMaintenanceAmount: 0, //可平账的maintenance金额
				discountOtherAmount: 0 //可平账的其他费用
			},
			estimateSettlementData: {
				currentRepayAmount: null, //这笔还款还的钱
				hadPaidAmount: null, //已经还了多少钱
				outstandingAmount: null //剩余待还的钱
			},
			loanTypeRepayList: [],
			earlyLoanType: [],
			earlyLoanId: [],
			installmentList: [],
			installment: null,
			repays: [],
			id: '',
			disburseTime: null,
			preRepays: [],
			repays2: [],
			preRepaymentIds: [],
			repayRadio: 0,
			preRepaymentId2: ''
		}
	},
	created() {
		this.$nextTick(() => {
			// apiDisburseEarlyInfo()
		})
	},
	computed: {
		loanColumns() {
			return [
				{
					// 案件ID
					title: this.$t('table.finance.cloums.loanId'),
					dataIndex: 'casePrefix',
					align: 'center',
					width: 150,
					customRender: (v, o) => (v ? v + '-' + o.caseId : '')
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 联系方式
					title: this.$t('table.applyInfo.cloums.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '')
				},
				{
					// 放款金额
					title: this.$t('table.applyInfo.cloums.putAmount'),
					dataIndex: 'disburseAmount',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'money_slot' }
				},
				{
					// 待还金额
					title: 'Total Outstanding',
					dataIndex: 'totalOutstanding',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'money_slot' }
				},

				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		},
		installmentsOptions() {
			let arr = []
			for (let index = 1; index < this.loanColumnData.installmentsNumbers + 1; index++) {
				arr.push(index)
			}
			return arr
		},
		earlyRecordColunms() {
			return [
				{
					title: 'Repayment Type',
					align: 'center',
					dataIndex: 'transactionTypeEnum',
					customRender: (v, o) => {
						return this.$t(`table.finance.repayment.payTypeMap.${v}`)
					}
				},
				{
					title: 'Actual Payment Time',
					dataIndex: 'transactionDate',
					align: 'center'
				},
				{
					title: 'Actual Amount Received / Remaining Amount',
					dataIndex: 'amount',
					align: 'center',
				},
				{
					title: 'Reference No.',
					dataIndex: 'paymentId',
					align: 'center'
				},
				{
					title: 'Repayment Receipt',
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					title: 'Document Password',
					dataIndex: 'password',
					align: 'center',
				},
				{
					title: 'Operation',
					align: 'center',
					scopedSlots: { customRender: 'action' }
				},
			]
		},
		prePaymentColumns(){
			return [
				{
					title: 'Repayment Type',
					align: 'center',
					dataIndex: 'transactionTypeEnum',
					customRender: (v, o) => {
						return this.$t(`table.finance.repayment.payTypeMap.${v.value}`)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId'
				},
				{
					title: 'Actual Payment Time',
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: 'Actual Amount Received',
					align: 'center',
					dataIndex: 'amount'
				},
				{
					title: 'Remaining Pre-Payment Amount',
					customRender: (obj) => {
						return (
							<span>{ obj.remainsAmount? obj.remainsAmount.toFixed(2) : 0}</span>
						)
					},
					align: 'center'
				},
				{
					title: 'Operation',
					align: 'center',
					scopedSlots: { customRender: 'action' }
				},
			]
		}
	},
	methods: {
		moment: moment,
		resetFormModel() {
			this.formModelData = {
				loanId: '',
				paymentId: '',
				transactionTypeEnum: 0,
				carPlateNo: '',
				transactionDate: null,
				transactionTime: null,
				transactionFiles: '',
				amount: '',
				isDiscount: 0,
				discountInterestAmount: 0,
				discountPenaltyAmount: 0,
				discountTrackingAmount: 0,
				discountMaintenanceAmount: 0,
				discountOtherAmount: 0,
				extraReason: '',
				needClosedLoan: 0,
				settlementParams: [],
				repayLoan: null,
				password: '',
				inputNTSATransferFee: 0,
			}
			this.earlyLoanType = [];
			this.earlyLoanId = [];
			this.installmentList = [];
			this.repays = [];
			this.installment = null;
			this.id = '';
		},
		uploadRepayment(loanId, formModelData, parentSource) {
			this.resetFormModel()
			this.needEarlySettlement = 0
			this.needEarlySettlementChange()
			this.needAutoSearch = loanId ? true : false
			!formModelData ? '' : (this.formModelData = { ...this.formModelData, ...formModelData })
			this.parentSource = parentSource
			if(parentSource === 'repaymentEntryEarly' || parentSource === 'repaymentEntryEarly2' || parentSource === 'repaymentEntryAuction') {
				this.needEarlySettlement = 1;
			}
			if(parentSource === 'repaymentEntryAuction') {
				this.formModelData.transactionTypeEnum = 2
			}
			this.needAutoFilling = (parentSource !== 'repaymentEntry' && parentSource !== 'repaymentEntryEarly' && parentSource !== 'repaymentEntryEarly2' && parentSource !== 'repaymentEntryAuction')
			if (this.needAutoSearch) {
				this.searchKeyword = loanId
				this.searchLoan()
			}
			this.repayRadio = 0;
			this.repayModalShow = true
		},
		handModalOk() {
			if (!this.loanColumnData.loanId && this.repayRadio == 0) {
				this.$message.error('please choose loan')
				return false
			} else if (!this.formModelData.paymentId && this.repayRadio == 0) {
				this.$message.error('please input paymentId')
				return false
			} else if (!this.formModelData.transactionDate && this.repayRadio == 0) {
				this.$message.error('please input time')
				return false
			} else if (this.formModelData.amount === '' && this.repayRadio == 0) {
				this.$message.error('please input amount')
				return false
			} else if (this.formModelData.transactionFiles === '' && !this.needAutoFilling && this.repayRadio == 0) {
				this.$message.error('please upload Repayment Receipt')
				return false
			}
			if (this.formModelData.transactionTypeEnum === 2 && this.loanColumnData.carInfos.length > 1 && this.repayRadio == 0) {
				if (this.formModelData.carPlateNo === '') {
					this.$message.error('please select Registration No')
					return false
				}
			}
			if ( this.formModelData.isDiscount === 1 && !(
					this.formModelData.discountTrackingAmount +
						this.formModelData.discountInterestAmount +
						this.formModelData.discountPenaltyAmount +
						this.formModelData.discountMaintenanceAmount +
						this.formModelData.discountOtherAmount >
					0
				)
			) {
				this.$message.error('Discount Sum Amount Cannot Be 0')
				return false
			}
			if (!this.formModelData.extraReason && this.formModelData.isDiscount === 1) {
				this.$message.error('please input Discount Reason')
				return false
			}
			this.formModelData.transactionDate = this.repayRadio == 0?this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss'): ''
			if (this.parentSource === 'bankAccountChecking') {
				if (!this.formModelData.transactionTime) {
					this.$message.error('please input time')
					return false
				}
				this.formModelData.transactionDate =
					this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD') +
					' ' +
					this.moment(this.formModelData.transactionTime).format('HH:mm:ss')
			}
			if (this.needEarlySettlement === 1) {
				let arrBool = true
				for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
					if (!this.formModelData.settlementParams[index].installment) {
						this.$message.error('Please select the period')
						arrBool = false
						break
					}
				}
				if (!arrBool) {
					return arrBool
				}
			}
			this.$confirm({
				title: '',
				content:
					this.formModelData.needClosedLoan === 1
						? this.whetherToOverpay
							? this.$t('table.applyInfo.msgMap.msg6')
							: this.$t('table.applyInfo.msgMap.msg5')
						: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					if (this.parentSource === 'bankAccountChecking') {
						this.whetherToOverpay
							? apiDtbUploadOverpaid({
									paymentId: this.formModelData.paymentId,
									loanId: this.loanColumnData.loanId,
									transactionDate: this.formModelData.transactionDate
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiDtbUploadRepay({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'mapesaAccountChecking') {
						this.whetherToOverpay
							? apiMpesaUploadOverpaid({
									paymentId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiMpesaUploadRepay({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'mpesaRepayment') {
						this.whetherToOverpay
							? apiOverpaidUpload({
									recordId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiUploadLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'checkRepayment') {
						this.whetherToOverpay
							? apiOverpaidUpload({
									recordId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiCheckLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if(this.parentSource === 'repaymentEntry'){
						this.whetherToOverpay
							? apiOverpaidRepay({
									loanId: this.loanColumnData.loanId, //案件id
									repayType: this.formModelData.transactionTypeEnum, //还款类型，同录入还款的还款类型
									paymentId: this.formModelData.paymentId, //还款id
									transactionDate: this.formModelData.transactionDate, //还款时间
									transactionFiles: this.formModelData.transactionFiles, //还款凭证
									carPlateNo: this.formModelData.carPlateNo, //收车时候，多辆车需要填是哪辆车
									overpaidAmount: this.formModelData.amount, //超额偿还金额
									password: this.formModelData.password
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId, isPrePayment: this.repayRadio,preRepaymentId: this.preRepaymentId2 }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					}
				},
				onCancel() {}
			})
		},
		previewRepay() {
			if (!this.loanColumnData.loanId && this.repayRadio == 0) {
				this.$message.error('please choose loan')
				return false
			}
			if (this.formModelData.amount === '' && this.repayRadio == 0) {
				this.$message.error('amount is empty!')
				return false
			}
			if (this.needEarlySettlement === 1) {
				let arrBool = true
				for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
					if (!this.formModelData.settlementParams[index].installment) {
						this.$message.error('Please select the period')
						arrBool = false
						break
					}
				}
				if (!arrBool) {
					return arrBool
				}
			}
			if (this.whetherToOverpay) {
				this.$message.error('Loan Cleared,please check it in Details!')
				return false
			}
			if (!this.formModelData.transactionDate && this.repayRadio == 0) {
				this.$message.error('Actual Repayment Time is empty!')
				return false
			}
			this.formModelData.transactionDate = this.repayRadio == 0?this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss'): ''
			apiPreview({ ...this.formModelData, loanId: this.loanColumnData.loanId, isPrePayment: this.repayRadio,preRepaymentId: this.preRepaymentId2 }).then((res) => {
				this.previewRepayTable = true
				this.previewRepayParams = {
					repayPlan: res.loanRepaymentPreviewVos,
					insurancePlans: res.loanInsurancePreviewVos,
					emergencyPlans: res.loanEmergencyPreviewVos,
					overpaidDetail: res.overpaidDetail,
					columnLoanType: this.loanColumnData.loanType,
					type: 0,
				}
			})
		},
		searchLoan() {
			this.selectedRows = []
			this.selectedRowKeys = []
			if (!this.searchKeyword) {
				this.$message.error('name is required')
				return false
			}
			let keyword = ''
			if (/^[A-Za-z]{3} ?[0-9]{3}[A-Za-z]$/.test(this.searchKeyword)) {
				keyword = this.searchKeyword.replace(/\s*/g, '')
			} else {
				keyword = this.searchKeyword
			}
			apiFindLoanByName({ keyword: keyword }).then((res) => {
				if (res.content.length === 0) {
					this.$message.success('Search Success')
				}
				this.loanDataSource = res.content
				this.disburseTime = res.content?res.content[0].disburseTime: null
				this.preRepays = [];
				if(res.content && res.content[0]?.preRepays){
					this.preRepaymentId2 = (res.content[0].preRepays && res.content[0].preRepays.length>0)?res.content[0].preRepays[0].id: ''
					res.content[0].preRepays.map(item => {
						this.preRepays.push({...item,  checked: false})
					})
				}
				this.loanDataSource.length === 1 ? this.changeRow([0], this.loanDataSource) : ''
			})
		},
		disabledDate(value){
			if(this.disburseTime && value){
				return value.valueOf() < this.moment(this.disburseTime).valueOf()
			} else {
				return false
			}
		},
		changeRow(k, r) {
			this.selectedRowKeys = k
			this.selectedRows = r
			this.loanColumnData = r[0]
			if (this.loanColumnData.loanStatus > 1004) {
				this.formModelData.isDiscount = 0
				this.formModelData.needClosedLoan = 1
				this.whetherToOverpay = true
			} else {
				this.formModelData.isDiscount = 0
				this.formModelData.needClosedLoan = 0
				this.whetherToOverpay = false
			}
			this.getApiDisburseEarlyInfo()
			this.getDiscountAmount()
		},
		getDiscountAmount() {
			apiDiscountAmount({ loanId: this.loanColumnData.loanId }).then((res) => {
				this.apiDiscountData = res
			})
		},
		getEstimateSettlement(loanType, installment) {
			this.formModelData.settlementParams.map((item) => {
				if (item.loanType === loanType) {
					item.installment = installment
				}
			})
			if (!this.loanColumnData.loanId) {
				// this.$message.error('Please choose loan')
				return false
			}
			if (!this.formModelData.transactionDate) {
				// this.$message.error('Actual Repayment Time is empty!')
				return false
			}
			let arrBool = true
			for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
				if (!this.formModelData.settlementParams[index].installment) {
					// this.$message.error('Please select the period')
					arrBool = false
					break
				}
			}
			if (!arrBool) {
				return arrBool
			}
			this.formModelData.transactionDate = this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss')
			apiEstimateSettlement({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
				this.estimateSettlementData = res
			})
		},
		needEarlySettlementChange() {
			if (this.needEarlySettlement === 1) {
				this.formModelData.needClosedLoan = 0
			} else {
				this.formModelData.settlementParams = []
			}
		},
		// 查看详情
		Detail(obj) {
			const pageDetail = obj.type === 'disbursed' ? 'lendingdetail' : 'applydetail'
			let routeData = this.$router.resolve({
				name: `menus.customerManagementMenu.${pageDetail}`,
				query: {
					id: obj.loanId
				}
			})
			this.$store.commit('tabs/addTab', {
				name: `menus.customerManagementMenu.${pageDetail}`,
				fullPath: `/home/customerManagement/${pageDetail}?id=${obj.loanId}`
			})
			window.open(routeData.href, '_blank')
			this.$store.commit('tabs/setActiveTab', '/home/finance/repayment')
		},
		getApiDisburseEarlyInfo() {
			var hasIpf =  false;
			apiDisburseRepayInfo({ loanId: this.loanColumnData.loanId }).then((res) => {
				this.loanTypeRepayList = res.map((item) => {
					const loan = {}
					loan.loanType = item
					if (item === 8) {
						loan.value = 4
					} else if (item === 7) {
						loan.value = 1
					} else {
						loan.value = item > 4 ? item - 3 : 1
					}
					return loan
				})
				hasIpf = this.loanTypeRepayList.findIndex(item1 => item1.loanType == 8) > -1 ||  this.loanTypeRepayList.findIndex(item1 => item1.loanType == 5) > -1
				if(this.parentSource == 'repaymentEntryEarly' || this.parentSource == 'repaymentEntryEarly2') {
					apiDisburseEarlyInfo({ loanId: this.loanColumnData.loanId }).then((res2) => {
						this.settlApiList = res2.map((item2) => {
							item2.disabled = false
							item2.checked = false
							item2.installment = null
							if(item2.loanType < 5  && hasIpf){
								item2.disabled = true
							} else if(item2.loanType == 7 || item2.loanType == 5 || item2.loanType == 8) {
								item2.disabled = true
							}
							return item2
						})
						this.installmentList = this.settlApiList?this.settlApiList[0].installmentList: []
						this.GetInitData()
					})
				} else if(this.parentSource == 'repaymentEntryAuction'){
					this.earlyLoanType = []
					apiDisburseEarlyInfo({ loanId: this.loanColumnData.loanId }).then((res2) => {
						this.settlApiList = res2.map((item2) => {
							item2.disabled = true
							item2.checked = true
							item2.installment = null
							this.earlyLoanType.push(item2.loanType)
							return item2
						})
						this.installmentList = this.settlApiList?this.settlApiList[0].installmentList: []

						this.GetInitData()
					})
				} else {
					this.settlApiList = []
				}
			})
		},
		GetInitData() {
			if(this.parentSource === 'repaymentEntryEarly' || this.parentSource === 'repaymentEntryEarly2' || this.parentSource === 'repaymentEntryAuction'){
				apiEarlyDetail({loanId: this.searchKeyword}).then(res => {
					if(res.repays && res.repays.length > 0){
						res.repays.map(item => {
							item.transactionTypeEnum = item.transactionTypeEnum.value
						})
					}
					this.id = res.id;
					this.repays = res.repays || [];
					this.repays2 = res.repays?[...res.repays]: [];;
					this.formModelData.paymentId = '';
					this.formModelData.transactionTypeEnum = 2;
					this.formModelData.transactionDate = null;
					this.formModelData.amount = '';
					this.formModelData.password = '';
					this.formModelData.transactionFiles = '';
					this.getEstimateAmount()
				})
			}
		},
		setCheckedVal(checkedValues) {
			this.formModelData.settlementParams = []
			this.settlApiList.map((item) => {
				item.checked = checkedValues.includes(item.loanType)
				if (item.checked) {
					this.formModelData.settlementParams.push({
						loanType: item.loanType,
						settlementLoanId: item.settlementLoanId,
						installment: item.installment
					})
				}
				return item
			})
		},
		ChangeRepayLoan(){
			if(this.formModelData.repayLoan.includes(this.loanTypeRepayList[0].value)){
				this.formModelData.repayLoan = this.loanTypeRepayList.map(item => {return item.value})
			}
		},
		ChangeEarlyRepayLoan() {
			if([0,1,2,3,4,7].some(value => this.earlyLoanType.includes(value))){
        this.earlyLoanType = [];
        this.settlApiList.map(item => {
          this.earlyLoanType.push(item.loanType)
          item.checked = true
        })
			} else {
        this.settlApiList.map(item => {
          if(this.earlyLoanType.includes(item.loanType)){
            item.checked = true
          } else {
            item.checked = false
          }
        })
			}
			this.getEstimateAmount()
		},
		getEstimateAmount(){
			let postdata = {
				id: this.id,
				repays: this.repays,
				loanId: this.loanColumnData.loanId,
				isDiscount: this.formModelData.isDiscount,
				discountTrackingAmount: this.formModelData.discountTrackingAmount,
				discountMaintenanceAmount: this.formModelData.discountMaintenanceAmount,
				discountInterestAmount: this.formModelData.discountInterestAmount,
				discountPenaltyAmount: this.formModelData.discountPenaltyAmount,
				discountOtherAmount: this.formModelData.discountOtherAmount,
				inputNTSATransferFee: this.formModelData.inputNTSATransferFee,
				type: (this.parentSource == 'repaymentEntryEarly' || this.parentSource == 'repaymentEntryEarly2')?0:1,
        earlySettlementParams: [],
				preRepaymentIds: this.preRepaymentIds
			}
      this.settlApiList.map(item => {
        if(item.checked && item.installment && postdata.type == 0){
          postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: item.installment,
            settlementLoanId: item.settlementLoanId,
          })
        } else if(postdata.type == 1){
					postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: item.installment,
            settlementLoanId: item.settlementLoanId,
          })
				}
      })

			if(postdata.earlySettlementParams.length == 0){
				return false
			}
			apiEarlyEstimate(postdata).then(res => {
				this.estimateSettlementData = res
			})
		},
		DeleteRepays(id) {
			apiEarlyDelete({id}).then(res => {
				if(res.repays){
					res.repays.map(item => {
						item.transactionTypeEnum = item.transactionTypeEnum.value
					})
				}
				this.repays = res.repays || [];
				this.repays2 = res.repays?[...res.repays]: [];
				this.preRepaymentIds = []
				this.preRepays.map(item => {
					if(item.checked) {
						this.preRepaymentIds.push(item.id)
						this.repays2.push({
							transactionTypeEnum: item.transactionTypeEnum.value,
							transactionDate: item.transactionDate,
							amount: item.amount,
							paymentId: item.paymentId,
							transactionFiles: item.transactionFiles,
							password: '',
							checked: true
						})
					}
				})

				this.getEstimateAmount()
			})
		},
		AddEarlyRepay(){
			if (!this.formModelData.paymentId) {
				this.$message.error('please input paymentId')
				return false
			} else if (!this.formModelData.transactionDate) {
				this.$message.error('please input time')
				return false
			} else if (this.formModelData.amount === '') {
				this.$message.error('please input amount')
				return false
			}  else if (this.formModelData.transactionFiles === '') {
				this.$message.error('please upload Repayment Receipt')
				return false
			}
			this.formModelData.transactionDate = this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss')
			let postdata = {
				loanId: this.loanColumnData.loanId,
				amount: this.formModelData.amount,
				transactionTypeEnum: this.formModelData.transactionTypeEnum,
				paymentId: this.formModelData.paymentId,
				transactionDate: this.formModelData.transactionDate,
				transactionFiles: this.formModelData.transactionFiles,
				password: this.formModelData.password,
			}
			apiSaveEarly(postdata).then(res => {
				res.repays.map(item => {
					item.transactionTypeEnum = item.transactionTypeEnum.value
				})
				this.id = res.id;
				this.repays = res.repays;
				this.repays2 = res.repays?[...res.repays]: [];
				this.preRepaymentIds = []
				this.preRepays.map(item => {
					if(item.checked) {
						this.preRepaymentIds.push(item.id)
						this.repays2.push({
							transactionTypeEnum: item.transactionTypeEnum.value,
							transactionDate: item.transactionDate,
							amount: item.amount,
							paymentId: item.paymentId,
							transactionFiles: item.transactionFiles,
							password: '',
							checked: true
						})
					}
				})

				this.formModelData.paymentId = '';
				this.formModelData.transactionTypeEnum = 2;
				this.formModelData.transactionDate = null;
				this.formModelData.amount = '';
				this.formModelData.password = '';
				this.formModelData.transactionFiles = '';
				this.getEstimateAmount()
			})
		},
		previewEarlyRepay(){
      let postdata = {
				id: this.id,
				repays: this.parentSource == 'repaymentEntryEarly2'?[]:this.repays,
				loanId: this.loanColumnData.loanId,
				isDiscount: this.formModelData.isDiscount,
				discountTrackingAmount: this.formModelData.discountTrackingAmount,
				discountMaintenanceAmount: this.formModelData.discountMaintenanceAmount,
				discountInterestAmount: this.formModelData.discountInterestAmount,
				discountPenaltyAmount: this.formModelData.discountPenaltyAmount,
				discountOtherAmount: this.formModelData.discountOtherAmount,
				inputNTSATransferFee: this.formModelData.inputNTSATransferFee,
				type: (this.parentSource == 'repaymentEntryEarly' || this.parentSource == 'repaymentEntryEarly2')?0:1,
        earlySettlementParams: [],
        preRepaymentIds: this.preRepaymentIds,
			}

      this.settlApiList.map(item => {
        if(item.checked && item.installment && postdata.type == 0){
          postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: item.installment,
            settlementLoanId: item.settlementLoanId
          })
        } else if(postdata.type == 1) {
					postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: null,
            settlementLoanId: item.settlementLoanId
          })
				}
      })

			if(postdata.earlySettlementParams.length == 0){
				this.$message.error('Please select Settlement and period')
				return false
			}

			apiEarlyPreview(postdata).then(res => {
				this.previewRepayTable = true
				this.previewRepayParams = {
					type: 1,
					repayPlan: res.loanRepaymentPreviewVos,
					insurancePlans: res.loanInsurancePreviewVos,
					emergencyPlans: res.loanEmergencyPreviewVos,
					overpaidDetail: res.overpaidDetail,
					columnLoanType: this.loanColumnData.loanType
				}
			})
		},
		SubmitEarlyRepament(){
      let postdata = {
				id: this.id,
				repays: this.repays,
				loanId: this.loanColumnData.loanId,
				isDiscount: this.formModelData.isDiscount,
				discountTrackingAmount: this.formModelData.discountTrackingAmount,
				discountMaintenanceAmount: this.formModelData.discountMaintenanceAmount,
				discountInterestAmount: this.formModelData.discountInterestAmount,
				discountPenaltyAmount: this.formModelData.discountPenaltyAmount,
				discountOtherAmount: this.formModelData.discountOtherAmount,
				inputNTSATransferFee: this.formModelData.inputNTSATransferFee,
				type: this.parentSource == 'repaymentEntryEarly'?0:1,
        earlySettlementParams: [],
				preRepaymentIds: this.preRepaymentIds,
			}
      this.settlApiList.map(item => {
        if(item.checked && item.installment && postdata.type == 0){
          postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: item.installment,
            settlementLoanId: item.settlementLoanId,
          })
        } else if(postdata.type == 1) {
					postdata.earlySettlementParams.push({
            id: item.id,
            loanId: item.loanId,
            relatedId: item.relatedId,
            loanType: item.loanType,
            settlementType: item.settlementType,
            installment: null,
            settlementLoanId: item.settlementLoanId,
          })
				}
      })

			if(this.repays?.length == 0 && postdata.preRepaymentIds.length == 0){
				this.$message.error('please add repayment records')
				return false
			} else if(postdata.earlySettlementParams.length == 0){
				this.$message.error('Please select Settlement and period')
				return false
			} else if ( this.formModelData.isDiscount === 1 && !( this.formModelData.discountTrackingAmount + this.formModelData.discountInterestAmount + this.formModelData.discountPenaltyAmount + this.formModelData.discountMaintenanceAmount + this.formModelData.discountOtherAmount > 0)) {
				this.$message.error('Discount Sum Amount Cannot Be 0')
				return false
			} else if (!this.formModelData.extraReason && this.formModelData.isDiscount === 1) {
				this.$message.error('please input Discount Reason')
				return false
			}
			apiEarlySubmit(postdata).then(res => {
				this.repayModalShow = false
				this.$emit('repaymentsInitTable', 1)
			})
		},
		ChangeCheck(){
			this.repays2 = this.repays?[...this.repays]: [];
			this.preRepaymentIds = [];
			let lastTrueIndex = -1;
			for(let i = this.preRepays.length - 1; i>= 0; i--){
				if(this.preRepays[i].checked){
					lastTrueIndex = i;
        	break;
				}
			}

			if(lastTrueIndex != -1){
				for(let i = 0; i <= lastTrueIndex; i++){
					this.preRepaymentIds.push(this.preRepays[i].id)
					this.preRepays[i].checked = true;
					this.repays2.push({
						transactionTypeEnum: this.preRepays[i].transactionTypeEnum.value,
						transactionDate: this.preRepays[i].transactionDate,
						amount: this.preRepays[i].amount,
						paymentId: this.preRepays[i].paymentId,
						transactionFiles: this.preRepays[i].transactionFiles,
						password: '',
						checked: true
					})
				}
			}
			this.getEstimateAmount()
		},
		ChangeRepayRadio(){
			this.formModelData.transactionTypeEnum = 0;
			this.formModelData.paymentId = '';
			this.formModelData.amount = 0;
			this.formModelData.transactionDate = '';
			this.formModelData.transactionTime = null;
			this.formModelData.transactionFiles = '';
			this.formModelData.password = '';
			this.formModelData.password = '';
		}
	}
}
</script>

<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 300px;
}

.btn_radio{
	width: 200px;
	height: 120px;
	margin-right: 30px;
	line-height: 120px;
	text-align: center;
	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
		border: 2px solid #1890ff;
		background: #fff;
		color: #1890ff
	}
}
</style>
